import http from "@/http";
// import { plainToClass } from "class-transformer";
// import { validate } from "class-validator";
import { ActionTree } from "vuex";
import localforage from "localforage";

import { DisplayOrientationEnum } from "@scrinz/dtos";
// import { RegisterDisplayRequestDto } from "@scrinz/dtos";
import { RootState } from "../types";
import {
	InstallationData,
	InstallationException,
	InstallationState,
	RegistrationException,
	RegistrationExceptionCodes,
} from "./types";

const INSTALLATION_DATA_KEY = "INSTALLATION_DATA";

export const actions: ActionTree<InstallationState, RootState> = {
	async init({ commit, dispatch }) {
		let data: any;

		try {
			// Load installation data.
			data = await localforage.getItem<any>(INSTALLATION_DATA_KEY);

			// Ensure data is an object.
			if (!data || typeof data !== "object") return;

			// Try to set installation with data.
			try {
				const installation = await dispatch("validateInstallationData", data);

				// Commit installation data.
				commit("SET_INSTALLATION", installation);
			} catch (err) {
				throw new InstallationException("Failed to validate installation data.", data);
			}
		} catch (err) {
			if (err instanceof InstallationException) {
				// TODO: Flag corrupted installation, present options to resolve.
				// tslint:disable-next-line
				console.error(
					"Installation is corrupted - should present option to resolve, based on existing installation data.",
				);
			} else {
				throw err;
			}
		}
	},

	async setInstallation({ commit, dispatch }, data: InstallationData) {
		const installation = await dispatch("validateInstallationData", data);
		await localforage.setItem(INSTALLATION_DATA_KEY, installation);
		commit("SET_INSTALLATION", installation);

		return installation;
	},

	async setOrientation({ dispatch, state }, orientation: DisplayOrientationEnum) {
		const { installation } = state;

		if (!installation) return;

		installation.orientation = orientation;

		return dispatch("setInstallation", installation);
	},

	async unsetInstallation({ commit }) {
		await localforage.removeItem(INSTALLATION_DATA_KEY);
		commit("SET_INSTALLATION", null);

		return true;
	},

	async validateInstallationData({}, data: InstallationData) {
		return data as InstallationData;

		// TODO: Re-enable validation once android bug is figured out

		// const errors = await validate(installation);

		// if (!(installation instanceof InstallationData) || errors.length > 0) {
		// 	throw new Error("Installation data is not valid.");
		// }

		// return installation;
	},

	async registerDisplay(
		{ dispatch, getters },
		payload: { apiKey: string; displayId: string } /* RegisterDisplayRequestDto */ & {
			orientation: DisplayOrientationEnum;
		},
	) {
		if (
			getters.installed &&
			!confirm(
				"This display is already registered. " +
					"Registring again will delete the current installation. " +
					"Are you sure you want to re-register?",
			)
		) {
			return;
		}

		const { apiKey, orientation, displayId } = payload;

		try {
			const res = await http.post("display/register", { apiKey, displayId, orientation });

			if (!res || res.status !== 201) {
				throw new Error("Failed to register display.");
			}

			return dispatch("setInstallation", {
				apiKey,
				orientation,
				displayId,
			});
		} catch (err: any) {
			if (err.response) {
				switch (err.response.status) {
					case 400:
						throw new RegistrationException(RegistrationExceptionCodes.DisplayNotFound, err, payload);
					case 403:
						throw new RegistrationException(RegistrationExceptionCodes.InvalidApiKey, err, payload);
					default:
						throw new RegistrationException(RegistrationExceptionCodes.HttpError, err, payload);
				}
			}

			if (err.config && err.config.url && err.message === "Network Error") {
				throw new RegistrationException(RegistrationExceptionCodes.NetworkError, err, payload);
			}

			throw new RegistrationException(RegistrationExceptionCodes.UnknownError, err, payload);
		}
	},
};
